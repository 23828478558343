import './index.scss';
import {UserInfo} from './components/userInfo.tsx'
import {MiningMachine} from './components/miningMachine.tsx'
import {MiningMachineBig} from './components/miningMachineBig.tsx'
import {Distributor} from './components/distributor.tsx'
import {SalesDetail} from './components/salesDetail.tsx'
import {CreateBitAgent} from './components/createBitAgent.tsx'
import {ModifyNum} from './components/modifyNum.tsx'
import {ConfirmDialog} from './components/confirmDialog.tsx'
import {RecycleDialog} from './components/recycleDialog.tsx'
import {useEffect, useState, useRef} from "react";
import {useMessage} from "src/view/components/MessageContext.tsx";
import {useGlobalState} from "src/hook/useGlobalState.ts";
import {MinerApi, User} from 'src/api'
import { useNavigate } from "react-router-dom";

export const Miner = () => {
    const [userInfo, setUserInfo] = useState(null as any)
    const [isShow, setIsShow] = useState<boolean>(false)
    const navigate = useNavigate()
    const { setAddress } = useGlobalState()
    const [accountAddress, setAccountAddress] = useState('')

    const [inventoryList, setInventoryList] = useState([] as InventoryList[])

    const [createTitle, setCreateTitle] = useState('' as string)

    const [createBitAgentVisible, setCreateBitAgentVisible] = useState<boolean>(false)

    const [modifyNumVisible, setModifyNumVisible] = useState<boolean>(false)
    const [modifyNumTitle, setModifyNumTitle] = useState('' as string)
    const [modifyNumBtnLabel, setModifyNumBtnLabel] = useState('' as string)
    const [inputAddress, setInputAddress] = useState('' as any)
    const [initModifyList, setInitModifyList] = useState([] as any)

    const [targetAddress, setTargetAddress] = useState('' as string)

    const [totalBitLife, setTotalBitLife] = useState(0 as number)

    const [recycleInfo, setRecycleInfo] = useState({} as any)

    const [confirmVisible, setConfirmVisible] = useState<boolean>(false)
    const [recycleVisible, setRecycleVisible] = useState<boolean>(false)
    const {showMessage, closeMessage} = useMessage()

    const {address} = useGlobalState()

    const miningMachineRef:any = useRef();
    const miningMachineBigRef:any = useRef();
    const distributorRef:any = useRef();
    const salesDetailRef:any = useRef();

    useEffect(() => {
        const initUserInfo:any = localStorage.getItem('userInfo')
        const user = JSON.parse(initUserInfo)
        setUserInfo(user)
        if (!initUserInfo) {
            User.logout().then(() => {
                localStorage.removeItem('minerToken')
                localStorage.removeItem('userInfo')
                setAddress(null)
                setAccountAddress('')
                navigate('/welcome')
            })
        } else {
            setIsShow(true)
        }
    }, []);

    // 获取矿机仓库
    const getInventoryList = async (address:string, targetEthAddress?:string) => {
        try {
            const res = await MinerApi.getGeneStock(address, 1, 30, targetEthAddress)
            setInventoryList(res.data.list)
        } catch (error) {}
    }
    const judgeInfo = () => {
        const initUserInfo:any = localStorage.getItem('userInfo')
        const user = JSON.parse(initUserInfo)
        setUserInfo(user)
        // type 0矿商1分销商2子分销
        if (user.type === 0) {
            setCreateTitle('Create BitAgent')
        } else if (user.type === 1) {
            setCreateTitle('Create Sub-BitAgent')
        }
    }
    // 增加、减少数量、创建分销商、回收账户刷新列表
    const refreshInventory = () => {
        miningMachineRef?.current?.callChildMethod(address);
        miningMachineBigRef?.current?.callChildMethod(address);
        distributorRef?.current?.callChildMethod(address);
        salesDetailRef?.current?.callChildMethod(address);
    }
    // 修改昵称刷新订单列表
    const refreshOrder = () => {
        salesDetailRef?.current?.callChildMethod(address);
    }
    useEffect(() => {
        if (address) {
            judgeInfo()
        }
    }, [address]);
    // 打开创建分销商弹框
    const createBitAgent = () => {
        setCreateBitAgentVisible(true)
    }
    // 关闭创建分销商弹框
    const closeCreateBitAgent = () => {
        setCreateBitAgentVisible(false)
    }
    // 点击下一步，关闭创建分销商弹框，打开修改数量弹框
    const clickNext = async (inputAddress:any) => {
        await getInventoryList(address)
        setCreateBitAgentVisible(false)
        setModifyNumVisible(true)
        setModifyNumTitle(createTitle)
        setModifyNumBtnLabel('Create')
        setInputAddress(inputAddress)
    }
    // 点击增加或者减少数量
    const operateNum = async (type:string, ethAddress:string) => {
        if (type === 'add') {
            setModifyNumTitle('Increase Quantity')
            setModifyNumBtnLabel('Confirm')
        } else {
            setModifyNumTitle('Decrease Quantity')
            setModifyNumBtnLabel('Confirm')
        }
        setTargetAddress(ethAddress)
        await getInventoryList(address, ethAddress)
        setModifyNumVisible(true)
    }
    // 关闭修改数量弹框
    const closeModifyNum = () => {
        setModifyNumVisible(false)
    }
    // 点击修改数量组件里的确定按钮
    const clickConfirm = (title:string, bitlifeList: any) => {
        const totalNum = bitlifeList.reduce((acc:any, cur:any) => {
            return acc += cur.inputNum
        }, 0)
        if (totalNum === 0) {
            showMessage('Please give at least 1 stock')
            return
        }
        setTotalBitLife(totalNum)
        if (title.includes('Create')) {
            clickCreate(bitlifeList)
        } else {
            // clickConfirmModifyNum(title, bitlifeList)
            setConfirmVisible(true)
            setInitModifyList(bitlifeList)
        }
    }
    // 创建分销商
    const isCreateLoading = useRef(false)
    const clickCreate = async (bitlifeList:any) => {
        if (!isCreateLoading.current) {
            try {
                isCreateLoading.current = true
                const bo = {
                    ethAddress: address,
                    sellerAddress: inputAddress,
                    createResellerGeneBOList: bitlifeList.map((item:any) => {
                        return {
                            geneBaseId: item.geneBaseId,
                            num: item.inputNum
                        }
                    })
                }
                const res:any = await MinerApi.createDistributor(bo)
                if (res.code === 200) {
                    showMessage('Created Successfully')
                    setModifyNumVisible(false)
                    isCreateLoading.current = false
                    refreshInventory()
                } else {
                    showMessage(res.message)
                    isCreateLoading.current = false
                }
            } catch (error) {
                isCreateLoading.current = false
            }
        }
    }
    // 增加减少数量
    const isModifyNumLoading = useRef(false)
    const clickConfirmModifyNum = async (title:string, bitlifeList: any) => {
        if (!isModifyNumLoading.current) {
            try {
                isModifyNumLoading.current = true
                const bo = {
                    add: title.includes('Increase') ? 1 : -1,
                    ethAddress: address,
                    sellerAddress: targetAddress,
                    createResellerGeneBOList: bitlifeList.map((item:any) => {
                        return {
                            geneBaseId: item.geneBaseId,
                            num: item.inputNum
                        }
                    })
                }
                const res:any = await MinerApi.modifyNum(bo)
                if (res.code === 200) {
                    if (title.includes('Increase')) {
                        showMessage('Created Successfully')
                    } else {
                        showMessage('Decreased successfully')
                    }
                    setModifyNumVisible(false)
                    setConfirmVisible(false)
                    isModifyNumLoading.current = false
                    refreshInventory()
                } else {
                    showMessage(res.message)
                    isModifyNumLoading.current = false
                }
            } catch (error) {
                isModifyNumLoading.current = false
            }
        }
    }
    const closeConfirmDialog = () => {
        setConfirmVisible(false)
    }
    const ensure = () => {
        clickConfirmModifyNum(modifyNumTitle, initModifyList)
    }

    const closeRecycleDialog = () => {
        setRecycleVisible(false)
    }
    const recycleAccount = (info:any) => {
        setRecycleVisible(true)
        setRecycleInfo(info)
    }

    // 回收账户
    const isRecycleLoading = useRef(false)
    const confirmRecycle = async () => {
        if (!isRecycleLoading.current) {
            try {
                isRecycleLoading.current = true
                const res:any = await MinerApi.recycleAccount(address, recycleInfo.ethAddress)
                if (res.code === 200) {
                    setRecycleVisible(false)
                    showMessage('Decrease Successful')
                    isRecycleLoading.current = false
                    refreshInventory()
                } else {
                    isRecycleLoading.current = false
                    showMessage(res.message)
                }
            } catch (error) {
                isRecycleLoading.current = false
            }
        }
    }
    return (
        <div>
            {
                isShow &&
                <div className={'miner-wrap'}>
                    {
                        userInfo?.type !== 0 &&
                        <div className={'mb-16'}>
                            <UserInfo refresh={refreshOrder}></UserInfo>
                        </div>
                    }
                    {
                        userInfo?.type === 2 &&
                        <div className={'mb-16'}>
                            <MiningMachineBig ref={miningMachineBigRef}></MiningMachineBig>
                        </div>
                    }
                    {
                        userInfo?.type !== 2 &&
                        <div className={'show-flex-box-r'}>
                            <MiningMachine ref={miningMachineRef}></MiningMachine>
                            <Distributor ref={distributorRef} clickCreateBitAgent={createBitAgent}
                                         operateNum={operateNum}
                                         recycleAccount={recycleAccount}></Distributor>
                        </div>
                    }
                    <div className={'mt-16'}>
                        <SalesDetail ref={salesDetailRef}></SalesDetail>
                    </div>
                    {createBitAgentVisible &&
                        <CreateBitAgent title={createTitle} btnLabel={'Next'} closeDialog={closeCreateBitAgent}
                                        clickNext={clickNext}></CreateBitAgent>}
                    {modifyNumVisible &&
                        <ModifyNum title={modifyNumTitle} btnLabel={modifyNumBtnLabel} inventoryList={inventoryList}
                                   targetAddress={targetAddress} closeDialog={closeModifyNum}
                                   clickConfirm={clickConfirm}></ModifyNum>}
                    {confirmVisible &&
                        <ConfirmDialog title={modifyNumTitle} totalBitLife={totalBitLife} targetAddress={targetAddress}
                                       closeDialog={closeConfirmDialog} ensure={ensure}></ConfirmDialog>}
                    {recycleVisible &&
                        <RecycleDialog totalNUm={recycleInfo.availableStockNum} name={recycleInfo.nickName}
                                       closeDialog={closeRecycleDialog}
                                       ensure={confirmRecycle}></RecycleDialog>}
                </div>
            }
        </div>
    )
}