import './distributor.scss'
import {handlePermillage} from "src/utils/utils.ts";
import {NoData} from 'src/view/components/noData.tsx'
import React, {useEffect, useState, useImperativeHandle, forwardRef, useRef} from "react";
import {useGlobalState} from "src/hook/useGlobalState.ts";
import {MinerApi} from 'src/api'
import {Loading} from "src/view/components/loading.tsx";
interface distributorProps {
    clickCreateBitAgent: () => void,
    operateNum: (type: string, ethAddress: string) => void,
    recycleAccount: (info: any) => void,
}
export const Distributor = forwardRef(({ clickCreateBitAgent, operateNum, recycleAccount }: distributorProps, ref) => {
    const [userInfo, setUserInfo] = useState({} as any)
    const {address} = useGlobalState()
    const tableTitleFour = ['BitAgent', 'BitLife Quantity', 'Sub-BitAgent Quantity', 'Actions']
    const tableTitleThree = ['BitAgent', 'BitLife Stock', 'Actions']
    const [title, setTitle] = useState([] as any)
    const [resellerList, setResellerList] = useState([] as ResellerList[])
    const [isLoading, setIsLoading] = useState(true as boolean)
    // const widthStyle = {
    //     width: `calc(100% / ${tableTitle.length})`,  // 根据状态变化背景颜色
    // };
    useEffect(() => {
        const initUserInfo:any = localStorage.getItem('userInfo')
        const user = JSON.parse(initUserInfo)
        if (user?.type === 0) {
            setTitle(tableTitleFour)
        } else if (user?.type === 1) {
            setTitle(tableTitleThree)
        }
    }, []);
    // 获取分销商
    const getResellerList = async (address:string) => {
        try {
            const res:any = await MinerApi.getResellerList(address, 1, 99)
            setIsLoading(false)
            setResellerList(res.data.list)
        } catch (error) {}
    }
    useImperativeHandle(ref, () => ({
        callChildMethod: getResellerList
    }));
    useEffect(() => {
        if (address) {
            getResellerList(address)
        }
    }, [address]);
    const widthStyleFour = [
        { width: '15%' },
        { width: '20%' },
        { width: '25%' },
        { width: '40%' }
    ];
    const widthStyleThree = [
        { width: '20%' },
        { width: '20%' },
        { width: '60%' },
    ];
    const getUser = () => {
        const initUserInfo:any = localStorage.getItem('userInfo')
        const user = JSON.parse(initUserInfo)
        setUserInfo(user)
    }
    useEffect(() => {
        getUser()
    }, []);
    return (
        <div className={'pt-22 pl-24 ml-24 distributor-wrap'}>
            <div className={'color-white f-20 pt-19 pl-40 distributor-title'}>
                BitAgent List
            </div>
            <div className={'show-flex-box-r show-flex-center cursor-pointer c-00E6FF f-18 create-btn'} onClick={clickCreateBitAgent}>
                {userInfo?.type === 0 ? 'Create BitAgent' : 'Create Sub-BitAgent'}
            </div>
            {
                title.length === 4 &&
                <div className={'distributor-list'}>
                    <div className={'mt-6 pl-40 show-flex-box-r show-flex-sb table-title'}>
                        {
                            title.map((item: string, index: number) => (
                                <div className={'c-59D f-18 single-title text-center'} style={widthStyleFour[index]}
                                     key={index}>{item}</div>
                            ))
                        }
                    </div>
                    {
                        isLoading &&
                        <div className={'pr-20 table-content'}>
                            <Loading></Loading>
                        </div>
                    }
                    {
                        !isLoading &&
                        <div className={'mr-16 pb-10 table-content'}>
                            {
                                resellerList.map((item: any, index: number) => (
                                    <div className={'pl-40 mt-16 show-flex-box-r show-flex-sb single-distributor'}
                                         key={index}>
                                        <div className={'color-white f-18 text-center mt-2 break-word'}
                                             style={widthStyleFour[0]}>{item.nickName}</div>
                                        <div className={'color-white f-18 text-center'}
                                             style={widthStyleFour[1]}>{handlePermillage(item.availableStockNum)} / {handlePermillage(item.issuedStockNum)}</div>
                                        <div className={'color-white f-18 text-center'}
                                             style={widthStyleFour[2]}>{item.resellerNum}</div>
                                        <div className={'show-flex-box-r'} style={widthStyleFour[3]}>
                                            {
                                                !item.isValid &&
                                                <div className={'show-flex-box-r'}>
                                                    <div className={'c-00E6FF f-18 cursor-pointer add-btn'}
                                                         onClick={() => operateNum('add', item.ethAddress)}>Increase
                                                    </div>
                                                    <div className={'c-00E6FF f-18 ml-40 cursor-pointer minus-btn'}
                                                         onClick={() => operateNum('minus', item.ethAddress)}>Decrease
                                                    </div>
                                                    <div className={'c-00E6FF f-18 ml-40 cursor-pointer takeBack-btn'}
                                                         onClick={() => recycleAccount(item)}>Reclaim
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                ))
                            }
                            {resellerList.length === 0 && <NoData></NoData>}
                        </div>
                    }
                </div>
            }

            {
                title.length === 3 &&
                <div className={'distributor-list'}>
                    <div className={'mt-6 pl-40 show-flex-box-r show-flex-sb table-title'}>
                        {
                            title.map((item: string, index: number) => (
                                <div className={'c-59D f-18 single-title text-center'} style={widthStyleThree[index]}
                                     key={index}>{item}</div>
                            ))
                        }
                    </div>
                    <div className={'mr-16 pb-10 table-content'}>
                        {
                            resellerList.map((item: any, index: number) => (
                                <div className={'pl-40 mt-16 show-flex-box-r show-flex-sb single-distributor'}
                                     key={index}>
                                    <div className={'color-white f-18 text-center mt-2 break-word'}
                                         style={widthStyleThree[0]}>{item.nickName}</div>
                                    <div className={'color-white f-18 text-center'}
                                         style={widthStyleThree[1]}>{handlePermillage(item.availableStockNum)} / {handlePermillage(item.issuedStockNum)}</div>
                                    <div className={'show-flex-box-r show-flex-center'} style={widthStyleThree[2]}>
                                        {
                                            !item.isValid &&
                                            <div className={'show-flex-box-r'}>
                                                <div className={'c-00E6FF f-18 cursor-pointer add-btn'}
                                                     onClick={() => operateNum('add', item.ethAddress)}>Increase
                                                </div>
                                                <div className={'c-00E6FF f-18 ml-40 cursor-pointer minus-btn'}
                                                     onClick={() => operateNum('minus', item.ethAddress)}>Decrease
                                                </div>
                                                <div className={'c-00E6FF f-18 ml-40 cursor-pointer takeBack-btn'}
                                                     onClick={() => recycleAccount(item)}>Reclaim
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            ))
                        }
                        {resellerList.length === 0 && <NoData></NoData>}
                    </div>
                </div>
            }

        </div>
    )
})